import React from 'react'
import Layout from '../components/Layout'
import MessageGridStaticQuery from '../components/MessageGridStaticQuery'

const CivCorpsChiefMessages = () => (
  <Layout>
    <MessageGridStaticQuery />
  </Layout>
)

export default CivCorpsChiefMessages
