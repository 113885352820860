import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import MessageCard from './MessageCard'

const MessageThumbnailStaticQuery = props => (
  <StaticQuery
    query={graphql`
      query getMessageDefThumbnail {
        fileName: file(relativePath: { eq: "civCorpsMessage-thumb.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allFile {
          edges {
            node {
              dir
              name
              extension
              publicURL
              base
              sourceInstanceName
              relativeDirectory
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <MessageCard
          message={props.message}
          msgthumbnails={data.allFile.edges}
          defaultThumbnail={data.fileName}
          slug={props.slugPath}
        />
      )
    }}
  />
)

export default MessageThumbnailStaticQuery;
