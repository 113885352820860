import React, { Component } from 'react'
import MessageThumbnailStaticQuery from './MessageThumbnailStaticQuery'

class MessageGrid extends Component {
  displayMessages = () =>
    this.props.masterTable
      .map(item => item.node)
      .filter(msg => msg.Status === 'Active')
      .map((msg, i) => {
        const slugPath = `/CivCorpsMessage/${msg.Title.toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')}`
        return (
          <div
            className="usa-width-one-third"
            style={{ marginBottom: '48px' }}
            key={msg.ID + i}
          >
            <MessageThumbnailStaticQuery message={msg} slugPath={slugPath} />
          </div>
        )
      })

  render() {
    return (
      <div className="usa-grid">
        <h1 style={{ marginTop: '15px' }}>Civilian Corps Chief Messages</h1>
        {this.displayMessages()}
      </div>
    )
  }
}

export default MessageGrid
