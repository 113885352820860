import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import MessageGrid from './MessageGrid'

const MessageGridStaticQuery = () => (
  <StaticQuery
    query={graphql`
      query AllMessages {
        allBtswebViewtypeJson(filter: { ViewType: { eq: "Message" } }) {
          edges {
            node {
              ID
              Title
              Caption
              Author
              ApprovalStatus
              Status
              Type
              ViewType
              PubDate
            }
          }
        }
      }
    `}
    render={data => (
      <MessageGrid masterTable={data.allBtswebViewtypeJson.edges} />
    )}
  />
)

export default MessageGridStaticQuery;
