import React from 'react'
import styled from 'styled-components'
import { FaClock } from 'react-icons/fa'
import { Link } from 'gatsby'

const Card = styled.div`
  position: relative;
  margin: 0.5rem 0 1rem 0;
  background-color: #ffffff;
  color: #212121;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`

const CardDetail = styled.div`
  padding: 24px;
  border-radius: 0 0 2px 2px;
`

const CardSnippet = styled.p`
  margin: 0;
  color: #aeb0b5;
  padding-bottom: 48px;
`

const MessageCard = ({ message, msgthumbnails, defaultThumbnail, slug }) => {
  const msgThumbFiles = msgthumbnails.filter(msg =>
    msg.node.base.includes('_thumbnail')
  )

  const msgThumbnailFile = msgThumbFiles.find(
    msg =>
      msg.node.base ===
      `${message.Title.split('/')[0]}_thumbnail.${message.Title.split('/')[1]}`
  )

  let thumbnailSrc = ''
  if (msgThumbnailFile !== undefined) {
    thumbnailSrc = msgThumbnailFile.node.publicURL
  } else {
    thumbnailSrc = defaultThumbnail.childImageSharp.fluid.src
  }

  return (
    <Card>
      <CardDetail>
        <Link to={slug}>
          <img
            src={thumbnailSrc}
            height="260px"
            width="auto"
            alt="Civ Corps Message thumbnail"
          />
          <h4 className="card_title">
            {// Cleans up post title in case there is no image attached
            message.Title.slice(-3) === 'jpg'
              ? message.Title.slice(0, -4)
              : message.Title}
          </h4>
        </Link>
        {/* FSP@BTS: Removes html tags and limits to 30 characters */}
        <CardSnippet
          dangerouslySetInnerHTML={{
            __html: message.Caption.substring(0, 100).replace(
              /<\/?[^>]+(>|$)/g,
              ''
            ),
          }}
        />

        <div className="usa-grid" style={{ padding: 0 }}>
          <div className="usa-width-one-half" style={{ padding: 0 }}>
            <FaClock />
            {message.PubDate}
          </div>
          <div className="usa-width-one-half" style={{ padding: 0 }}>
            <b>by</b> {message.Author}
          </div>
        </div>
      </CardDetail>
    </Card>
  )
}

export default MessageCard
